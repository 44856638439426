import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import Header from "./../../components/Header/Header.jsx";
import Footer from "./../../components/Footer/Footer.jsx";
import GridContainer from "./../../components/Grid/GridContainer.jsx";
import GridItem from "./../../components/Grid/GridItem.jsx";
import HeaderLinks from "./../../components/Header/HeaderLinks.jsx";
import Parallax from "./../../components/Parallax/Parallax.jsx";
import "assets/scss/material-kit-react.scss?v=1.4.0";
import 'typeface-roboto';
import 'typeface-roboto-slab';

import styles from "./../../assets/jss/material-kit-react/views/wirtshausPage.jsx";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from '@material-ui/core/withWidth';
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import {graphql, StaticQuery} from 'gatsby';
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import CookieSnackbar from "../Components/CookieSnackbar";
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry";

const pageQuery = graphql`
  {
    allGraphCmsWirtshaus {
      nodes {
        header {
          localFile {
            localURL
          }
        }
        gallary {       
          image {             
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
              }
              localURL
            }                                                      
          }
          col
          alt      
        }
        title
        text
      }
    }
  }
`;

class WirtshausPage extends React.Component {
    render() {
        const {classes, ...rest} = this.props;
        const props = this.props;
        const imageClasses = classNames(
            classes.imgWidth,
            classes.imgRaised,
            classes.imgRoundedCircle,
            classes.imgFluid,
        );
        return <StaticQuery query={pageQuery}>{({allGraphCmsWirtshaus: { nodes }}) => {
            const{gallary, title, header} = nodes[0]; // select first, whatever this is!
            const columns = props.width == 'sm' || props.width == 'xs' || props.width == 'md' ? 1 : 2
            return <div>
                <Header
                    color="transparent"
                    brand="Gasthaus Häring"
                    brandLink="/"
                    rightLinks={<HeaderLinks/>}
                    fixed
                    changeColorOnScroll={{
                        height: 200,
                        color: "dark"
                    }}
                    {...rest}
                />
                <Parallax small filter image={header.localFile.localURL}/>
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section}>
                        <div className={classes.container}>
                            <GridContainer justify="center">
                                <GridItem xs={12} sm={12} md={6}>
                                    <div className={classes.profile}>
                                        <div className={classes.name}>
                                            <h2 className={classes.title}>{title}</h2>
                                        </div>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            <div className={classes.description} style={{paddingBottom: "40px"}}>
                                <h4>
                                    Die <b>Wirtsstub{"‘"}n</b> und das <b>Nebenzimmer</b> ist ideal für Familienfeiern
                                    aller Art sowie Vereinsabende und Versammlungen bis zu 100 Personen.
                                    <br/>
                                    Der <b>Festsaal</b> bietet Platz für ca. 300 Personen für Veranstaltungen, Geburtstage oder
                                    Hochzeiten. Der Wirt{"’"}s-Stadl empfiehlt sich dazu noch als perfekte Lokation für
                                    die Weinstube.
                                </h4></div>
                            <ResponsiveMasonry columnsCountBreakPoints={{600: 1, 900: 2, 1500: 3}}>
                                <Masonry gutter={5}>{gallary.map(o =>
                                    <GatsbyImage alt={o.alt} image={getImage(o.image.localFile)}/>)}
                                </Masonry>
                            </ResponsiveMasonry>
                        </div>
                    </div>
                    <div style={{paddingBottom: "60px"}}/>
                </div>
                <Footer/>
                <CookieSnackbar/>
            </div>}}
        </StaticQuery>;
    }
}

export default withWidth()(withStyles(styles)(WirtshausPage));
